import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Legal } from '../layouts/Legal';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Legal title="Terms and Conditions">{children}</Legal>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`FIVE NINES DIGITAL LTD.`}</strong></p>
    <p>{`SOFTWARE ACCESS TERMS AND CONDITIONS (“`}<strong parentName="p">{`TERMS`}</strong>{`”)`}</p>
    <p>{`These Terms apply to the Order referencing you as Customer (“`}<strong parentName="p">{`Customer`}</strong>{`” or “`}<strong parentName="p">{`You`}</strong>{`”) and Five Nines Digital Ltd (“`}<strong parentName="p">{`Supplier`}</strong>{`” or “`}<strong parentName="p">{`Us`}</strong>{`”) and these Terms, and are effective as of the date the Order is signed by both parties (“`}<strong parentName="p">{`Order Date`}</strong>{`”). The Terms together with the Order constitute the “`}<strong parentName="p">{`Agreement`}</strong>{`”.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Definitions and Interpretation`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">{`The definitions in this clause apply to the Agreement:`}</li>
        </ol>
      </li>
    </ol>
    <p><strong parentName="p">{`Confidential Information`}</strong>{`: has the meaning given in clause 6.1.`}</p>
    <p><strong parentName="p">{`Claim`}</strong>{`: has the meaning given in clause 9.2.`}</p>
    <p><strong parentName="p">{`Customer Terms:`}</strong>{` means Customer’s standard terms of sale for its goods and services which it makes available to its customers.`}</p>
    <p><strong parentName="p">{`Deposit:`}</strong>{` means an appropriate sum of money held as security against Customer’s obligations under this Agreement.`}</p>
    <p><strong parentName="p">{`Documentation`}</strong>{`: means the printed materials and documents made available by us to you in conjunction with the Software.`}</p>
    <p><strong parentName="p">{`Fees`}</strong>{`: means any fees payable under an Order and this Agreement (including Subscription Fees, Professional Service Fees or Payment System Fees).`}</p>
    <p><strong parentName="p">{`Subscription Fees`}</strong>{`: the fee payable by you to us for access to the Software under an Order.`}</p>
    <p><strong parentName="p">{`Initial Term`}</strong>{`: has the meaning given in clause 2.2.`}</p>
    <p><strong parentName="p">{`Intellectual Property Rights`}</strong>{`: patents, utility models, rights to inventions, copyright and related rights, trade marks, and service marks, trade names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to preserve the confidentiality of information (including know-how and trade secrets) and any other intellectual property rights, whether registered or unregistered and including all applications for (and rights to apply for and be granted), renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of the world.`}</p>
    <p><strong parentName="p">{`Permitted Venue`}</strong>{`: the venues of Customer for which the Software may be used as agreed with Supplier in the Order for which Customer pays the Subscription Fee.`}</p>
    <p><strong parentName="p">{`Payment System`}</strong>{`: a feature of the Software enabling Customer to take payments from its customers through the Software, in accordance with the Customer Terms, which Customer may opt to use as described in more details at `}<a parentName="p" {...{
        "href": "https://www.try.be/pay-terms"
      }}><em parentName="a">{`https://www.try.be/pay-terms`}</em></a>{` for which Customer shall pay a Payment System Fee.`}</p>
    <p><strong parentName="p">{`Payment System Fee`}</strong>{`: the fee payable by Customer to Supplier for the Payment System feature of the Software as set out in the Order.`}</p>
    <p><strong parentName="p">{`Premium Features`}</strong>{`: means any additional feature to the Software that Supplier has agreed to provide to Customer in accordance with the terms of an Order.`}</p>
    <p><strong parentName="p">{`Professional Services`}</strong>{` means the customization, development, implementation and/or training services as defined in an Order.`}</p>
    <p><strong parentName="p">{`Professional Service Fees`}</strong>{`: means the fees payable by Customer in respect of the Professional Services as set out in an Order.`}</p>
    <p>{` `}<strong parentName="p">{`Order`}</strong>{`: the order form signed by you and us setting out the detail of the fees (including the Subscription Fee, Referral Fee, Professional Service Fees and any Payment System Fees) payable and the Licensed Venues which may use the Software and Documents issued by Supplier to you.`}</p>
    <p><strong parentName="p">{`Representation`}</strong>{`: has the meaning given in clause 12.2.`}</p>
    <p><strong parentName="p">{`Representatives`}</strong>{`: has the meaning given in clause 6.3.`}</p>
    <p><strong parentName="p">{`Software`}</strong>{`: the Supplier's proprietary computer program, referred to as 'Trybe', which enables a Customer to manage and process customer bookings via the Website, carry out their inventory management, sell retail products, manage membership databases, sell memberships, and report on the Customer’s business.`}</p>
    <p>{` `}<strong parentName="p">{`SLA`}</strong>{`: the online document referenced the Order which sets out the terms upon which the Software and Support Services will be provided to you by Supplier.`}</p>
    <p><strong parentName="p">{`Support Services`}</strong>{`: the provision of technical advice and assistance on the Software to Customer by Supplier in accordance with the SLA.`}</p>
    <p><strong parentName="p">{`Term`}</strong>{`: the Initial Term and such further extended terms of use of the Software as provided in and in accordance with clause 2.2.`}</p>
    <p><strong parentName="p">{`VAT`}</strong>{`: value added tax chargeable under applicable law from time to time and any similar additional tax(es).`}</p>
    <p><strong parentName="p">{`Website`}</strong>{`: Supplier's website, `}<a parentName="p" {...{
        "href": "http://www.try.be/"
      }}>{`www.try.be`}</a>{`, by which the Software is accessed.`}</p>
    <ol>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">{`Unless the context otherwise requires: (i) words in the singular shall include the plural and in the plural shall include the singular; (ii) a reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time; and (iii) any words following the terms including, include, in particular, for example or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.`}</li>
          <li parentName="ol">{`A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) and that person's personal representatives, successors and permitted assigns.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Right of Access`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`In consideration of the Fees paid by the Customer to the Supplier, the Supplier grants to the Customer a non-exclusive, non-transferable right to access the Software and the Documents for the Term in relation to the Licensed Venue(s) and any other terms set out in the Order, in accordance with this Agreement.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The initial term of the Agreement is three (3) months from the Order Date set out in the Order (Initial Term). The Customer may terminate the Agreement after the Initial Term by providing to the Supplier not less than fourteen (14) days written notice of termination prior to the expiry of the Initial Term. If the Customer does not terminate the Agreement thus at the end of the Initial Term, the Agreement will automatically continue for another nine (9) months commencing on the day after the end of the Initial Term. The Customer may then terminate the Agreement after this nine (9) month period by giving the Supplier not less than fourteen (14) days written notice of termination prior to the expiry of the nine (9) month period and if the Customer does not terminate the Agreement at the end of such period, the Agreement will automatically renew for additional twelve (12) month periods until terminated by either party giving not less than fourteen (14) days written notice of termination to the other prior to the expiry of the then current twelve (12) month period, to take effect on the end of the then current twelve month period. This clause is without prejudice to the parties' rights of termination under clause 11 (Termination).`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Customer may access the Software via the Website and using an assigned username and password which Supplier shall provide and which is strictly for the use of designated employees of the Customer for the Licensed Venues only. There are no restrictions on the number of Customer's employees at a Licensed Venue who can access the Software or the day or times the Software may be accessed by a Licensed Venue. The Customer shall use the Software only for its internal business purposes at the Licensed Venue(s), for processing customer bookings and associated tasks for such Licensed Venue(s).`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Customer may not use the Software other than as specified in clause 2 without the prior written consent of the Supplier and the Customer acknowledges that additional fees may be payable on any change of use or increase in Licensed Venues approved by the Supplier.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Except as expressly permitted in this Agreement or under applicable law, the Customer undertakes:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`not (and shall not permit any third party) to copy, adapt, reverse engineer, decompile, disassemble, or create derivative works based on the whole or any part of the Software or Documents nor attempt to do any such thing;`}</li>
              <li parentName="ol">{`not to rent, lease, sub-licence, loan, translate, merge, vary or modify the Software or Documents;`}</li>
              <li parentName="ol">{`not permit the Software or any part of it to be combined with, or become incorporated in, any other programs;`}</li>
              <li parentName="ol">{`to keep access to the Software and Documents secure and to maintain accurate and up-to-date records of the number and locations of all access rights to the Software at each Licensed Venue; and`}</li>
              <li parentName="ol">{`to supervise and control use of the Software and ensure that the Software is used by your employees and representatives in accordance with the terms of this Agreement.`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Customer agrees that it is liable for the acts and omissions of each authorised user/staff member, as though those acts and omissions were those of the Customer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Customer shall not without prior written consent of the Supplier: sub-license, assign or novate the benefit or burden of the Agreement in whole or in part.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Supplier may at any time sub-license, assign, novate, charge or deal in any other manner with any or all of its rights and obligations under this Agreement, provided it gives written notice to the Customer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Customer shall notify the Supplier as soon as it becomes aware of any unauthorised use of the Software by any person.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Supplier shall provide Support Services for the Software in accordance with the terms of the SLA. Supplier may access the Customer's bookings data logged on the Software for purposes of providing Support Services and for obtaining statistical industry analysis. The supplier shall not change any Customer data or share, sell or pass on Customer data to any third parties and any customer data collected on The Customer's booking widget or added to The Software is solely the property of The Customer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Except as otherwise set out in this Agreement, Supplier expressly reserve all rights to the Software, including without limitation, to publish, duplicate, process, use or exploit the Software.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Professional Services.`}</strong>{` `}</p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">{`Professional Services will be performed with due skill, care and ability in accordance with good industry practice, applicable laws and using appropriately trained personnel.  `}</li>
          <li parentName="ol">{`The performance of the Professional Services is contingent on Customer (i) meeting any dependencies set out in an Order, (ii) making decisions and providing information as necessary for Supplier to be able to provide the Professional Services, and (iii) allowing Supplier such access to its facilities, equipment and data as is reasonably required to provide the Professional Services.`}</li>
          <li parentName="ol">{`If any assumptions set out in an Order prove to be materially incorrect or Customer fails to comply with any of its obligations under this Agreement upon which the performance of the Professional Services is premised, Supplier reserves the right to make a reasonable adjustment to the Professional Services Fees and/or delivery dates for any affected Professional Services.`}</li>
          <li parentName="ol">{`Where applicable, the acceptance process for Professional Services shall be set out in the Order. Any acceptance tests and criteria shall be objective and based on agreed scenarios and agreed results with a view to demonstrating that the Professional Services materially conform to the Order. Customer is obliged to notify Supplier within 20 days of provision of the Professional Services if it does not believe Professional Services materially conform to their specifications, or else such Professional Services shall be deemed accepted.  `}</li>
          <li parentName="ol">{`In the event that Professional Services do not meet any acceptance tests as set out in an Order, Customer shall grant Supplier a reasonable period of time in which to correct any non-conforming Professional Services at no cost to Customer. Where Supplier corrects such non-conforming Professional Services within this time frame, then this shall be Customer’s sole and exclusive remedy in respect of such non-compliance.`}</li>
          <li parentName="ol">{`Any amendments or additions to the Professional Services, including scope and Professional Services Fees will only be valid if agreed in writing and executed by both Parties in the applicable Order.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Fees`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">{`Unless otherwise set out in an Order the Customer shall pay the Subscription Fee set out in the Order in full and in cleared funds by Direct Debit on the first banking day of every month during the Term of the Agreement. In respect of the Professional Service Fee (if applicable), unless otherwise set out in an Order, the Customer shall pay 50 percent on the Order Date and 50 percent upon completion of the services (subject to the issuing of an invoice). Supplier shall apply   Payment System Fees against   each payment processed by the Software on Customer’s behalf, and shall invoice such fees monthly in arrears to be paid by Customer by direct debit. Customer acknowledges and agrees that if Supplier is charged back any fees in respect of any transactions of Customer (including Customer’s customers) through the use of the Payment System, Supplier shall claim such fees back from Customer and Customer is solely responsible for such fees.`}</li>
          <li parentName="ol">{`The parties agree that the Supplier may review and increase all or part of the Fees not more than once in any 6 month period. The Supplier will notify the Customer in writing in advance of any such increase at least thirty (30) days prior to the proposed increase. If such increase is not acceptable to the Customer, it may terminate the Agreement by giving not less than 14 days' notice to expire no later than the date of the proposed increase.`}</li>
          <li parentName="ol">{`All Fees and other sums payable under this Agreement are exclusive of VAT or any other sales tax (including withholding tax), for which the Customer shall be responsible.`}</li>
          <li parentName="ol">{`If the Customer fails to make any payment due to the Supplier under the Agreement by the due date for payment, then, without limiting the Supplier's remedies under the Agreement, the Supplier shall be entitled to charge Customer interest on the overdue amount at the rate of 4% per annum above the base rate of Starling Bank from time to time. Such interest shall accrue on a daily basis from the due date until actual payment of the overdue amount, whether before or after judgment, The Customer shall pay the interest together with the overdue amount.`}</li>
          <li parentName="ol">{`If Customer’s internal processes require a purchase order or purchase order number to be submitted with an invoice, Customer shall provide to Supplier such purchase order and/or purchase order number with the signed Order. Payment of invoices shall not be delayed or withheld by Customer due to Customer’s internal ordering processes or formalities, including the requirement and/or failure to provide a purchase order number`}</li>
          <li parentName="ol">{`Where approved by Customer in advance (including under an Order), the Customer will reimburse Supplier for all reasonable travel, subsistence and other expenses incurred by Supplier’s staff and contractors in providing Professional Services to Customer.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Terms relating to the Payment System`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The Payment System operates using Stripe, a third-party service provider for payment services. Your use of the Payment System via Stripe through our Software is subject to the `}<a parentName="p" {...{
                "href": "https://stripe.com/gb/connect-account/legal"
              }}>{`https://stripe.com/gb/connect-account/legal`}</a>{` including the `}<a parentName="p" {...{
                "href": "https://stripe.com/gb/privacy"
              }}>{`https://stripe.com/gb/privacy`}</a>{` (collectively, the “Stripe Services Agreement”) and you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of Supplier enabling payment processing services through Stripe, you agree to provide us accurate and complete information about you and your Stripe account. By using Stripe, you hereby consent and authorize Supplier and Stripe to share any information and payment instructions you provide to the extent required to complete payments from your customers via the Software.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Customer agrees to be held responsible and liable for: (i) any and all chargebacks; and (ii) any assessment, fines, fees, charges or expenses of any nature, which are levied on Supplier at any time directly in relation to any aspect of our relationship with you in relation to the Payment System (all together defined herein as “`}<strong parentName="p">{`Assessment`}</strong>{`”). Each Assessment represents a debt immediately due and payable to us and Customer hereby indemnifies Supplier upon demand, for any loss, liability, cost and expense incurred by Supplier in connection with any such Assessment.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Supplier reserves the right to suspend or limit Customer’s ‘refund functionality’ within the Software, where the Customer is deemed by Supplier to be generating an excessive amount of chargebacks, and/or for any other risk related reasons. Supplier will notify the Customer of such limitation or suspension, subject to any restrictions by the applicable law, indicating the reasons for such limitation or suspension and the procedure for correcting factual mistakes that led to it where possible.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Supplier reserves the right to confirm the financial solvency of Customer on an ongoing basis. To facilitate this, upon request, Customer shall provide Supplier with:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`the latest Quarterly Management Accounts (Profit & Loss statement and Balance Sheet);`}</li>
              <li parentName="ol">{`the latest Unabridged Annual statutory accounts.`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Where Supplier, acting reasonably, deems that Customer’s financial situation presents a risk to Supplier in terms of Supplier’s relationship with Stripe, Supplier may a) request a Deposit from Customer as security against such risk and/or b) terminate this Agreement upon written notice, in each case without prejudice to any other right of Supplier under this Agreement.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Funds in the Deposit will remain there for up to 90 days following the date of termination of this Agreement.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Customer authorizes us to set-off by whatever means the whole or any part of Customer’s liabilities to us under this Agreement (or any other contract with us) against any funds credited to or owing to Customer under this Agreement (or any other contract with us). We may exercise this right of set-off at any time, without notice to Customer, whether either liability is present or future, liquidated or unliquidated, and whether or not either liability arises under this Agreement.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Customer shall ensure that it has in place with its customers comprehensive and legally compliant Customer Terms and a privacy policy, which set clearly and accurately the fees and payment terms applicable to customers, the type of data and lawful basis for the transfer of data provided by customers of Customer to Supplier and Stripe, and that customer payments are processed by third parties, being Supplier and Stripe. Customer shall provide links to its Customer Terms and privacy policy when requested by Supplier in connection with Professional Services. Supplier disclaims all and any liability to Customer and its customers, where Customer has failed to comply with this clause.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Confidentiality`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Except as permitted by clause 6.2, each party undertakes that it shall not at any time during the Term and for a period of two years after termination or expiration of this Agreement disclose to any third party any confidential information disclosed to it by the other party concerning the business and/or affairs of the other party, including without limitation information relating to a party's operation, technical or commercial know-how, specifications, inventions, processes or initiatives, plans, product information, pricing information, know-how, designs, trade secrets, software, documents, data and information which, when provided by one party to the other: a) are clearly identified as "Confidential" or "Proprietary" or are marked with a similar legend; b) are disclosed orally or visually, identified as Confidential Information at the time of disclosure and confirmed as Confidential Information in writing; or c) a reasonable person would understand to be confidential or proprietary at the time of disclosure (`}<strong parentName="p">{`Confidential Information`}</strong>{`). Supplier Confidential Information includes without limitation the Software and Documents. `}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Each party agrees that it shall take all reasonable measures to protect the secrecy of, and avoid disclosure or use of, Confidential Information of the other party in order to prevent it from falling into the public domain or the possession of persons other than those persons authorised under the Agreement. Such measures shall include, but not limited to, the highest degree of care that the receiving party utilises to protect its own Confidential Information of a similar nature, which shall be no less than reasonable care. Each party agrees to notify the other in writing of any actual or suspected misuse, misappropriation or unauthorised disclosure of Confidential Information of the disclosing party which may come to the receiving party's attention.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Each party and its group companies may disclose the other party's Confidential Information to its employees, officers, agents, consultants or sub-contractors (`}<strong parentName="p">{`Representatives`}</strong>{`) who need to know such information for the purposes of carrying out its obligations under this Agreement, provided that the disclosing party takes all reasonable steps to ensure that its Representatives comply with the confidentiality obligations contained in this clause 6; and as may be required by law, court order or any governmental or regulatory authority. The disclosing party shall be responsible for its Representatives' compliance with the confidentiality obligations set out in this clause.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Each party reserves all rights in its Confidential Information. No rights or obligations in respect of a party's Confidential Information other than those expressly stated in this Agreement are granted to the other party or are to be implied from this Agreement. In particular, no licence is hereby granted directly or indirectly under any Intellectual Property Right held, made, obtained or licensable by either party now or in the future.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Notwithstanding the foregoing, the receiving party shall have no liability to the disclosing party with regard to any Confidential Information which the receiving party can prove:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`was in or has entered the public domain at the time it was disclosed through no fault of the receiving party;`}</li>
              <li parentName="ol">{`was known to the receiving party, without restriction, at the time of disclosure;`}</li>
              <li parentName="ol">{`is disclosed with the prior written approval of the disclosing party;`}</li>
              <li parentName="ol">{`was independently developed by the receiving party without any use of the Confidential Information;`}</li>
              <li parentName="ol">{`becomes known to the receiving party, without restriction, from a source other than the disclosing party, without breach of this Agreement by the receiving party and otherwise not in violation of the disclosing party's rights; or`}</li>
              <li parentName="ol">{`is disclosed pursuant to an order of a court or other governmental or regulatory body; provided that the receiving party shall, to the extent permitted by law, provide the disclosing party with prompt notice of such court order to enable the disclosing party to seek a protective order or otherwise prevent or restrict such disclosure.`}</li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Limited Software Warranty and Warranty of Customer`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Subject to clause 7.2 and 7.5, Supplier warrants for the Term that the Software will perform as specified in the Documents, when used in accordance with the Documents and this Agreement, and that the functionality of the Software will not materially decrease during the Term.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Supplier does not warrant:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`that the use of the Software will be uninterrupted error-free;`}</li>
              <li parentName="ol">{`the performance of the Software outside the scope of the Documents; or`}</li>
              <li parentName="ol">{`that the Software has been developed to meet the individual requirements of Customer`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Should a warranty breach occur during the Term and the Customer notifies Supplier and provides all the information that may be necessary to assist Supplier in resolving the defect or fault, Supplier will, at its sole option and expense either repair or replace the Software or parts therein; and if Supplier cannot reasonably repair or replace the Software or parts therein, then Supplier shall at its option refund the Fee paid for the Software or parts therein and terminate the Customer's right to use the Software.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`To the maximum extent permitted by applicable law, the warranties and remedies provided in this clause 7 are exclusive and in lieu of all other warranties, terms and conditions, express, implied or statutory, including warranties, terms and conditions of merchantability, accuracy, correspondence with description, fitness for a purpose, satisfactory quality and non-infringement, all of which are, to the maximum extent permitted by applicable law, expressly disclaimed by Supplier. `}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In relation to the Payment System which is ultimately provided by Stripe, Customer acknowledges that this is provided to you on an “as is” “as available” basis, without any warranties, express, implied, or statutory.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Customer represents and warrants that the person entering into or otherwise accepting this Agreement on behalf of the Customer is duly authorised and has legal capacity to do so. The Customer represents and warrants to the Supplier that its acceptance of this Agreement and the performance of its obligations under the Agreement have been duly authorised and that the Agreement is binding on the Customer and enforceable in accordance with its terms.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Supplier is constantly improving and updating the Software and aims to provide new and innovative features and services. Customer shall always have access to the latest version of the Software. Customer acknowledges that Supplier may update or modify certain elements of the Software and introduce new Premium Features as Supplier sees fit, provided that Supplier shall comply with the warranties in set out in clause 7.1 for the Term.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Limitation of Liability`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">{`Subject to clause 8.2: the aggregate liability of Supplier for or in respect of any loss or damage suffered by Customer (whether due to breach of contract, tort (including negligence) or otherwise) under or in connection with this Agreement and the use of the Software, Documents, Professional Services and Support Services shall not exceed the Fees paid by Customer within the previous six months prior to such claim for damage, in respect of the item at issue under an Order.`}</li>
          <li parentName="ol">{`The exclusions in clause 8 shall apply to the fullest extent permissible at law, but the Supplier does not exclude liability for: death or personal injury caused by the negligence of the Supplier, its officers, employees, contractors or agents; fraud or fraudulent misrepresentation; or any other liability which may not be excluded by applicable law.`}</li>
          <li parentName="ol">{`Subject to clause 8.2, Supplier shall have no liability for any special, indirect or consequential losses or damages of any kind which may be suffered by the Customer (or any person claiming under or through the Customer) in connection with this Agreement or for loss of profits, anticipated savings, business opportunity, goodwill, or data (including corruption of or damage to data), or business interruption, whether or not such losses or damages are foreseeable; and whether the same arise in contract, tort (including negligence) or otherwise.`}</li>
          <li parentName="ol">{`Supplier is not responsible for and disclaims all liability for the delivery of goods or services to Customer’s customers or any other third parties, or providing customer service, notifications, or for consumer complaints, or taking other actions related the goods and services offered by Customers.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Intellectual Property and Indemnity`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The Customer acknowledges that all Intellectual Property Rights in the Software and Documents (and as may be updated from time to time) belong and shall remain with the Supplier and the Customer shall have no rights, title, ownership or interest in or to any Intellectual Property Rights in the Software and Documents other than the right to use it in accordance with the terms of this Agreement.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Supplier undertakes at its own expense to defend the Customer or, at its option, settle any claim or action brought against the Customer alleging that the proper use of the Software by Customer in accordance with this Agreement infringes the copyright of a third party (“`}<strong parentName="p">{`Claim`}</strong>{`”) and shall be responsible for any damages awarded against the Customer or agreed upon in settlement by Supplier as a result of or in connection with any such Claim in accordance with the terms set out below. For the avoidance of doubt, this clause 9.2. shall not apply where the Claim in question is attributable to`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`use of the Software (or any part thereof) by the Customer other than in accordance with this Agreement;`}</li>
              <li parentName="ol">{`use of the Software in combination with any hardware or software not supplied or specified by the Supplier if the infringement would have been avoided by the use of the Software not so combined; or`}</li>
              <li parentName="ol">{`unauthorised changes to the Software carried out by the Customer, or at the Customer's request.`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If a Claim is made against the Customer, the Supplier's obligations under clause 9.2 are conditional on the Customer:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`as soon as reasonably practicable, giving written notice of the Claim to the Supplier, specifying the nature of the Claim in reasonable detail;`}</li>
              <li parentName="ol">{`not making any admission of liability, agreement or compromise in relation to the Claim without the prior written consent of the Supplier (such consent not to be unreasonably conditioned, withheld or delayed);`}</li>
              <li parentName="ol">{`permitting Supplier to control the defence and settlement of the Claim; and providing Supplier with reasonable information and assistance for the defence or settlement of the Claim; and`}</li>
              <li parentName="ol">{`using all commercially reasonable efforts to mitigate any loss, damage or costs related to the Claim.`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If any Claim is made, or in the Supplier's reasonable opinion is likely to be made, against the Customer, the Supplier may at its sole option and expense:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`procure for the Customer the right to continue using the Software (or any part thereof) in accordance with this Agreement;`}</li>
              <li parentName="ol">{`modify the Software so that it ceases to be infringing;`}</li>
              <li parentName="ol">{`replace the Software with non-infringing software; or`}</li>
              <li parentName="ol">{`if in the Supplier's opinion none of the options above are commercially feasible, terminate the Agreement immediately by notice in writing to the Customer and refund any of the Subscription Fee paid by the Customer as at the date of the termination (less a reasonable sum in respect of the Customer's use of the Software to the date of termination) on return of the Software or disabling of access to the Software and all copies thereof.`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`This clause 9 constitutes the Customer's exclusive remedy and the Supplier's only liability in respect of Claims.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Data Protection`}</strong></p>
      </li>
    </ol>
    <p>{`The parties agree to comply with the provisions of Schedule 1.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Termination`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Without affecting any other right or remedy available to it, a party may terminate the Agreement with immediate effect by giving written notice to the other party if:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`the other party commits a material breach of any term(s) of this Agreement which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of seven days after being notified in writing to do so;`}</li>
              <li parentName="ol">{`the other party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they fall due or admits inability to pay its debts or is deemed unable to pay its debts within the meaning of section 123 of the Insolvency Act 1986;`}</li>
              <li parentName="ol">{`a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with the winding up of the other party; an application is made to the court, or an order is made, for the appointment of an administrator, or if a notice of intention to appoint an administrator is given or if an administrator is appointed, over the other party;`}</li>
              <li parentName="ol">{`a person becomes entitled to appoint a receiver over the assets of the Customer or a receiver is appointed over the assets of the Customer;`}</li>
              <li parentName="ol">{`any event occurs, or proceeding is taken, with respect to the other party in any jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in clause 11.1.3 to clause 11.1.4; or`}</li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of its business. In addition to its rights of termination under clause11.1, the Supplier shall be entitled to terminate the Agreement if the Customer fails to pay any amount due under this Agreement on the due date for payment and remains in default not less than 7 days after being notified in writing to make such payment.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Any provision of this Agreement that expressly or by implication is intended to come into or continue in force on or after termination of the Agreement shall remain in full force and effect.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Termination or expiry of the Agreement shall not affect any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of this Agreement which existed at or before the date of termination or expiry.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`On termination for any reason:`}</p>
          </li>
          <li parentName="ol">
            <ol parentName="li">
              <li parentName="ol">{`all rights granted to the Customer under this Agreement shall cease;`}</li>
              <li parentName="ol">{`the Customer shall cease all activities authorised by this Agreement;`}</li>
              <li parentName="ol">{`the Customer shall immediately pay to the Supplier any sums due to the Supplier under this Agreement up to the date of termination; and`}</li>
              <li parentName="ol">{`the Customer shall immediately destroy or return to the Supplier (at the Supplier's option) all copies of the Software and Documents in its possession, custody or control and, in the case of destruction, certify to the Supplier that it has done so; and`}</li>
              <li parentName="ol">{`each party shall return to the other all Confidential Information of the other party.`}</li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`General`}</strong></p>
      </li>
      <li parentName="ol">
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Waiver.`}</strong>{` No failure or delay by a party to exercise any right or remedy provided under this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.`}</li>
          <li parentName="ol"><strong parentName="li">{`Entire Agreement.`}</strong>{` This Agreement contains the whole agreement between the parties relating to the subject matter hereof, supersede all prior agreements, arrangements and understandings between the parties relating to that subject matter. Each party acknowledges that, in entering into the Agreement, it does not rely on any statement, representation, assurance or warranty (whether it was made negligently or innocently) of any person (whether a party to the Agreement or not) (`}<strong parentName="li">{`Representation`}</strong>{`) other than as expressly set out in this Agreement. Each party agrees that the only rights and remedies available to it arising out of or in connection with a `}<strong parentName="li">{`Representation`}</strong>{` shall be for breach of contract.`}</li>
          <li parentName="ol"><strong parentName="li">{`Amendments.`}</strong>{` No variation of an Order shall be effective unless it is amended in writing and signed by an authorised representative of each party. Supplier may update these Terms at any time during the Term by providing 30 days’ written notice to Customer, after which such revised Terms shall take effect.`}</li>
          <li parentName="ol"><strong parentName="li">{`Invalidity.`}</strong>{` If any provision or part-provision of this Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest of this Agreement.`}</li>
          <li parentName="ol"><strong parentName="li">{`Third Party Rights.`}</strong>{` A person who is not a party to this Agreement shall not have rights under this Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement, but this does not affect any right or remedy of a third party which exists, or is available, apart from that Act.`}</li>
          <li parentName="ol"><strong parentName="li">{`Independent Contractors.`}</strong>{` Nothing in this Agreement is intended to, or shall be deemed to, establish any partnership or joint venture between any of the parties, constitute any party the agent of another party, or authorise any party to make or enter into any commitments for on behalf of any other party.`}</li>
          <li parentName="ol"><strong parentName="li">{`Non-Solicitation.`}</strong>{` The Customer shall not, without the prior written consent of the Supplier, at any time from the date of this Agreement to the expiry of 6 months after termination or expiry of the Agreement, solicit or entice away from the Supplier or employ (or attempt to employ) any person who is, or has been, engaged as any employee, consultant or subcontractor of the Supplier in connection with the Agreement.`}</li>
          <li parentName="ol"><strong parentName="li">{`Force Majeure.`}</strong>{` Neither party shall be in breach of this Agreement nor liable for delay in performing, or failure to perform, any of its obligations under this Agreement if such delay or failure result from events, circumstances or causes beyond is reasonable control. In such circumstances the time for performance shall be extended by a period equivalent to the period during which performance of the obligation has been delayed or failed to be performed.`}</li>
          <li parentName="ol"><strong parentName="li">{`Notices.`}</strong>{` Any notice given to a party under or in connection with this Agreement shall be in writing and shall be delivered by email to the address on the Order (save that any notice relating to breach, disputes, or termination must also be sent by post to the address set out on an Order in addition to email).`}</li>
          <li parentName="ol"><strong parentName="li">{`Governing Law.`}</strong>{` This Agreement and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales. The parties irrevocably agree that the courts of England shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this Agreement or their subject matter or formation (including non-contractual disputes or claims).`}</li>
          <li parentName="ol"><strong parentName="li">{`Marketing`}</strong>{`. Customer agrees that Supplier may publish Customer’s name and logo in its customer lists, and promotional, marketing and investment materials, in any media. Any use of Customer’s name and logo shall be in accordance with the applicable brand guidelines as notified by Customer from time to time. Customer will co-operate with Supplier in producing any press releases or case studies regarding the supply of the Software to Customer and in providing references for Supplier (including taking reference calls from potential customers`}</li>
        </ol>
      </li>
    </ol>
    <p><strong parentName="p">{`SCHEDULE 1`}</strong></p>
    <p><strong parentName="p">{`DATA PROTECTION`}</strong></p>
    <p><strong parentName="p">{`1. Agreed Terms`}</strong></p>
    <p>{`1.1. In this Schedule the following words shall have the following meanings:`}</p>
    <p>{`1.2. "Personal Data Breach" means a breach of security leading to the destruction, loss, alteration, unauthorised disclosure of, or access to, personal data.`}</p>
    <p>{`1.3. "Data Protection Legislation" the General Data Protection Regulation (Regulation (EU) 2016/679), the UK GDPR, the Data Protection Act 2018 and all laws and regulations applicable to the relevant party relating to the processing of personal data under or in relation to the Agreement including, where applicable, the guidance and codes of practice issued by the Information Commissioner or any other applicable supervisory authority, and the equivalent of any of the foregoing in any relevant jurisdiction.`}</p>
    <p>{`1.4. "personal data", "data subject", "controller", "processor" and "process" shall be interpreted in accordance with applicable Data Protection Legislation.`}</p>
    <p><strong parentName="p">{`2. Processor Clauses`}</strong></p>
    <p>{`2.1. In the event that We process Your personal data under the Agreement, the parties record their intention that We are the processor and You are the controller of such personal data. Annex 1 to this Schedule 1 sets out the subject-matter and duration of the processing of Your personal data, the nature and purpose of the processing, the type of personal data and the categories of data subjects. `}</p>
    <p>{`2.2. Each party shall comply with its obligations under applicable Data Protection Legislation and You warrant and undertake that You shall not instruct Us to process Your personal data where such processing would be unlawful.`}</p>
    <p>{`2.3. Subject to paragraph 6 below, We shall process Your personal data only in accordance with Your documented instructions and shall not transfer Your Personal Data outside of the UK without Your consent. For the avoidance of any doubt, any configuration of the service by You shall constitute 'written instructions' for the purposes of this Schedule 1.`}</p>
    <p>{`2.4. We may process Your personal data other than in accordance with Your documented instructions where required to do so by applicable law provided that (unless prohibited by applicable law on important grounds of public interest) We shall notify You of such legal requirement before such processing.`}</p>
    <p>{`2.5. We shall ensure that individuals engaged in the processing of Your personal data under the Agreement are subject to obligations of confidentiality in respect of such personal data.`}</p>
    <p>{`2.6. We shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk involved in processing Your personal data pursuant to the Agreement. The security measures are set out in Annex 2 to this Schedule and You warrant that You have reviewed such security measures and consider them appropriate in the context of the processing of Your personal data as anticipated by the Agreement.`}</p>
    <p>{`2.7. We may engage such other processors ("Sub Processors") as We consider reasonably appropriate for the processing of Your personal data in accordance with the terms of the Agreement (including in connection with support, maintenance and development, staff augmentation and the use of third party data centres) provided that We shall notify You of the addition or replacement of such Sub Processors and You may, on reasonable grounds, object to a Sub Processor by notifying Us in writing within 5 days of receipt of Our notification, giving reasons for Your objection. The parties shall work together to reach agreement on the engagement of Sub Processors. We shall require all Sub Processors to enter into an agreement equivalent effect to the terms contained in paragraphs 2.3 to 2.6 inclusive and We shall remain responsible and liable for Sub Processor's acts and omissions.`}</p>
    <p>{`2.8. In the event that any data subject exercises its rights under applicable Data Protection Legislation against You, We shall respond without undue delay and shall use reasonable commercial efforts, to assist You in fulfilling Your obligations as controller within 5 days following written request from You provided that We may (a) extend such time period and/or (b) charge You on a time and materials basis in the event that we consider, in our reasonable discretion, that such assistance is onerous, complex, frequent or time consuming.`}</p>
    <p>{`2.9. Upon discovering a Personal Data Breach, We shall notify You without undue delay and within 72 hours and shall assist You to the extent reasonably necessary in connection with notification to the applicable Supervisory Authority and data subjects, taking into account the nature of processing and the information available to Us.`}</p>
    <p>{`2.10. In the event that You consider that the processing of personal data performed pursuant to the Agreement requires a privacy impact assessment to be undertaken, following written request from You, We shall use reasonable commercial endeavours to provide relevant information and assistance to You to facilitate such privacy impact assessment. We may charge You for such assistance on a time and materials basis.`}</p>
    <p>{`2.11. Unless otherwise required by applicable law, following termination or expiry of the Agreement We shall, return all your personal data to you (in a format that is supported by the Software) provided you make that request to us in writing within 10 days of the date of termination, after which we shall delete such data.`}</p>
    <p>{`2.12. Where requested by You, We shall make available all information necessary to demonstrate Our compliance with the foregoing paragraphs 2.3 to 2.11 inclusive and shall contribute to audits conducted by You or another auditor mandated by You.`}</p>
    <p>{`2.13. In the event that We consider that Your instructions relating to processing of Your personal data under the Agreement infringes Data Protection Legislation We shall inform You and You shall assess your instructions and Data Protection Legislation. We shall not be obliged to process any of Your personal data until You notify Us that Your instructions are non-infringing or amend Your instructions to make them non-infringing and notify Us accordingly.`}</p>
    <p><strong parentName="p">{`ANNEX 1 - DETAILS OF PROCESSING`}</strong></p>
    <p><strong parentName="p">{`We shall process the following types of personal data for the duration of the Agreement, for the purposes of us fulfilling our obligations under such Agreement only:`}</strong></p>
    <p><strong parentName="p">{`- First and last name of Customer’s customers`}</strong></p>
    <p><strong parentName="p">{`- Email address of Customer’s customers`}</strong></p>
    <p><strong parentName="p">{`- Phone number of Customer’s customers`}</strong></p>
    <p><strong parentName="p">{`- Address of Customer’s customers`}</strong></p>
    <p><strong parentName="p">{`- Information that Customer requests from its customers within the client information form built by Customer (as determined by Customer).`}</strong></p>
    <p>{`   `}<strong parentName="p">{`ANNEX 2 - SECURITY STANDARDS`}</strong></p>
    <p>{`We undertake to maintain`}</p>
    <ul>
      <li parentName="ul">{`ISO 27001:2013 certification`}</li>
      <li parentName="ul">{`Information security policies`}</li>
      <li parentName="ul">{`Organisation of information security`}</li>
      <li parentName="ul">{`Human resource security`}</li>
      <li parentName="ul">{`Asset management`}</li>
      <li parentName="ul">{`Access control`}</li>
      <li parentName="ul">{`Cryptography`}</li>
      <li parentName="ul">{`Physical and environmental security`}</li>
      <li parentName="ul">{`Operations security`}</li>
      <li parentName="ul">{`Communication security`}</li>
      <li parentName="ul">{`System acquisition, development and maintenance`}</li>
      <li parentName="ul">{`Supplier relationships that comply with confidentiality and data protection obligations in this Agreement`}</li>
      <li parentName="ul">{`Information security incident management`}</li>
      <li parentName="ul">{`Information security aspects of business continuity management`}</li>
      <li parentName="ul">{`Compliance; with internal requirements, such as policies, and with external requirements, such as laws`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      